<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>公司信息</el-breadcrumb-item>
      <el-breadcrumb-item>{{ pageTitle }}</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 视图区 -->
    <el-form :model="dateForm"
             :rules="dateFormRules"
             ref="dateFormRef"
             label-position="top">
      <el-tabs type="border-card">
        <el-tab-pane label="基本信息">
          <el-row :gutter="30">
            <el-col :span="16">
              <el-form-item label="新闻标题："
                            prop="name">
                <el-input v-model="dateForm.name"></el-input>
              </el-form-item>

              <el-form-item label="新闻概述："
                            prop="summary">
                <el-input type="textarea"
                          resize="none"
                          rows="6"
                          v-model="dateForm.summary"></el-input>
              </el-form-item>

              <!-- 图片上传  -->
              <el-form-item label="新闻封面："
                            prop="logo">
                <el-upload :data="imgtype"
                           :action="uploadURL"
                           :headers=" headerObj"
                           :limit="1"
                           :on-preview="handlePreview"
                           :before-upload="beforeUpload"
                           :on-success="handleSuccess"
                           :file-list="fileList"
                           list-type="picture">
                  <el-button size="small"
                             type="primary">点击上传</el-button>
                  <div slot="tip"
                       class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
                </el-upload>
                <el-dialog :visible.sync="imgDialogVisible"
                           width="50%"
                           append-to-body>
                  <img width="100%"
                       :src="dialogImageUrl"
                       alt="">
                </el-dialog>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>

        <el-tab-pane label="新闻详情">
          <el-form-item label="新闻内容：">
            <TEditor v-model="dateForm.content"
                     :baseUrl="baseUrl"></TEditor>
          </el-form-item>
          <el-form-item>
            <el-button type="primary"
                       @click="saveForm">提交</el-button>
            <el-button @click="goBack">取消</el-button>
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
    </el-form>
  </div>
</template>

<script>

export default {
  data () {
    return {

      addData: '添加新闻',
      updateData: '修改新闻信息',
      pageTitle: '',

      // 图片上传地址
      uploadURL: this.globalUrlapi + '/logo',
      headerObj: {
        Authorization: window.sessionStorage.getItem('token')
      },
      imgtype: {
        type: 'news'
      },
      fileList: [],
      imgDialogVisible: false,
      dialogImageUrl: '',

      dateForm: {
        id: '',
        name: '',
        summary: '',
        logo: '',
        content: ''
      },

      baseUrl: '/logo?type=news',

      // 验证规则
      dateFormRules: {
        name: [
          { required: true, message: '请输入新闻标题', trigger: 'blur' },
          { max: 100, message: '字数多于 100 个字符', trigger: 'blur' }
        ],
        summary: [
          { required: true, message: '请输入新闻简述', trigger: 'blur' },
          { max: 255, message: '字数多于 255 个字符', trigger: 'blur' }
        ]
      }
    }
  },

  methods: {
    goBack () {
      this.$router.push('/news')
    },

    // 查看图片
    handlePreview (fileList) {
      this.dialogImageUrl = fileList.url
      this.imgDialogVisible = true
    },

    // 图片上传前校验
    beforeUpload (file) {
      const isIMAGE = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt1M = file.size / 1024 / 500 < 1
      if (!isIMAGE) {
        this.$message.error('上传文件只能是 jpg 或 png 图片格式!')
      }
      if (!isLt1M) {
        this.$message.error('上传文件大小不能超过 500kb!')
      }
      return isIMAGE && isLt1M
    },

    // 图片上传成功回调
    handleSuccess (response) {
      this.dateForm.logo = response.data
    },

    // 获取当前id数据
    async getNewsDate (id) {
      const { data: res } = await this.$http.get('news/' + id)
      if (res.code !== 200) {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      this.dateForm = res.data
      this.fileList.push({ name: res.data.logo, url: this.globalUrlapi + res.data.logo })
    },

    // 新增方法
    async addForm () {
      const { data: res } = await this.$http.post('news', this.dateForm)
      if (res.code !== 200) {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      this.$message.success('添加成功！')
      // 跳转
      this.$router.push('/news')
    },

    // 修改方法
    async updateForm () {
      const { data: res } = await this.$http.put('news/' + this.dateForm.id, {
        name: this.dateForm.name,
        logo: this.dateForm.logo,
        summary: this.dateForm.summary,
        content: this.dateForm.content
      })
      if (res.code !== 200) {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      this.$message.success('修改成功')
      // 跳转
      this.$router.push('/news')
    },

    init () {
      // 判断是否有参数
      if (this.$route.params && this.$route.params.id) {
        this.getNewsDate(this.$route.params.id)
        this.pageTitle = this.updateData
      } else {
        this.pageTitle = this.addData
      }
    },

    saveForm () {
      this.$refs.dateFormRef.validate(valid => {
        if (!valid) return
        // 判断id是否存在  存在则为修改
        if (this.dateForm.id) {
          this.updateForm()
        } else {
          this.addForm()
        }
      })
    }
  },

  created () {
    this.init()
  }

}
</script>

<style>
</style>
